<template xmlns="http://www.w3.org/1999/html">
  <div class="main main--internal">
    <div class="page-intro">
      <div class="main-intro">
        <div class="page-intro__left">
          <div class="page-header__container">
            <h1 class="page-header page-header_w500">{{ title }}</h1>
          </div>
          <article class="article">
            <div class="article__block">
              <div class="article__content article__content_w500">
                <EditorJSComponent :text="description.text"/>
              </div>
            </div>
          </article>
          <div class="page-intro__forms">
            <div class="form-group">
              <div class="form-group__header">
                <span class="form-group__header__title">У Вас есть несовершеннолетние дети от брака?</span>
              </div>
              <div class="form-group__body">
                <div class="form-group__body__radio">
                  <label class="form-group__radio">
                    <input type="radio" v-model="stepForm.have_underage_children" :value="1">
                    <RadioStatesIcon/>
                    <span class="form-group__radio__title">Да</span>
                  </label>
                  <label class="form-group__radio">
                    <input type="radio" v-model="stepForm.have_underage_children" :value="0">
                    <RadioStatesIcon/>
                    <span class="form-group__radio__title">Нет</span>
                  </label>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="form-group__header">
                <span class="form-group__header__title">Вы заключали нотариальное удостоверенное соглашение об уплате алиментов?</span>
              </div>
              <div class="form-group__body">
                <div class="form-group__body__radio">
                  <label class="form-group__radio">
                    <input type="radio" v-model="stepForm.notarial_agreement" :value="1">
                    <RadioStatesIcon/>
                    <span class="form-group__radio__title">Да</span>
                  </label>
                  <label class="form-group__radio">
                    <input type="radio" v-model="stepForm.notarial_agreement" :value="0">
                    <RadioStatesIcon/>
                    <span class="form-group__radio__title">Нет</span>
                  </label>
                </div>
              </div>
            </div>
            <div v-if="stepForm.have_underage_children  === 0 || stepForm.notarial_agreement" class="note">
              <p class="note__title">Судбот вам не нужен</p>
              <ul class="note__description">
                <li v-if="stepForm.have_underage_children  === 0">Наш бот подготавливает только заявление о взыскании
                  алиментов на несовершеннолетних детей.
                </li>
                <li v-if="stepForm.notarial_agreement">При наличии нотариального удостоверенного соглашения об уплате
                  алиментов, оно имеет силу решения суда и Вам
                  для получения алиментов достаточно сразу сдать его в отдел судебных приставов, по месту жительства
                  плательщика алиментов.
                </li>
              </ul>
            </div>
            <div>
              <div class="note-true" v-if="stepForm.have_underage_children && stepForm.notarial_agreement === 0">
                <p class="note-true__title">Судбот Вам поможет!</p>
                <p class="note-true__description">Чтобы подготовить заявление, по которому суд взыщет алименты нажмите
                  кнопку "Начать".</p>
              </div>
             <span @click="analyticsSend"><router-link :to="{ name: 'aliment' }" tag="button"
                  v-if="!(stepForm.have_underage_children  === 0 || stepForm.notarial_agreement)"
                  :disabled="!(stepForm.have_underage_children && stepForm.notarial_agreement === 0)"
                  :class="['button button--fill', { 'button--disabled':  !(stepForm.have_underage_children && stepForm.notarial_agreement === 0) }]">
                  Начать
              </router-link></span>
            </div>
          </div>
        </div>
        <div class="page-intro__right">
          <div class="example-claim" sp-show-form="205709">
            <img src="/public/img/example_claim.png" alt="">
            <p>Чек-лист для иска на взыскание алиментов</p>
            <div class="flex-center" sp-show-form="205709">
              <button class="button button__blue" >Скачать</button>
            </div>
          </div>
        </div>
      </div>
      <section class="main__faq" v-if="faq && faq.length">
        <h4>Часто задаваемые вопросы</h4>
        <ul class="article__faq__list">
          <li class="article__faq__list__item" :class="{ active: activeFaq === index }" v-for="(item, index) in faq"
              :key="item.id">
            <div class="article__faq__list__item__header" @click="setActiveFaq(index)">
              <h3>{{ item.question }}</h3>
              <div>
                <PlusIcon/>
                <MinusIcon/>
              </div>
            </div>
            <p class="article__faq__list__item__text">{{ item.answer }}</p>
          </li>
        </ul>
      </section>
    </div>
  </div>
</template>

<script>

import PlusIcon from 'components/svg/Plus.vue'
import MinusIcon from 'components/svg/Minus.vue'
import RadioStatesIcon from 'components/svg/RadioStates.vue'
import EditorJSComponent from 'components/EditorJSComponent.vue'

export default {
  name: 'IntroAliment',
  components: {
    RadioStatesIcon,
    PlusIcon,
    MinusIcon,
    EditorJSComponent
  },
  async asyncData ({ store }) {
    await store.dispatch('GET_ALIMENT_PREVIEW_PAGE')
  },
  data () {
    return {
      activeFaq: null,
      stepForm: {
        have_underage_children: null,
        notarial_agreement: null
      }
    }
  },
  computed: {
    title () {
      return this.$store.state.aliment_preview_page.preview.title
    },
    description () {
      return this.$store.state.aliment_preview_page.preview
    },
    faq() {
      return this.$store.state.aliment_preview_page.preview.questions
    }
  },
  metaInfo() {
    const navigator = this.$store.state.aliment_preview_page.preview
    return this.$seo(
      'common',
      navigator.meta_title,
      navigator.meta_keywords,
      navigator.meta_description
    )
  },
  methods: {
    setActiveFaq(index) {
      if (this.activeFaq === index) {
        this.activeFaq = null
      } else {
        this.activeFaq = index
      }
    },
    analyticsSend(){
      gtag('event', 'click', {
        'event_category': 'buttons',
        'event_label': 'start_aliments'
      });
    }
  },
}
</script>
